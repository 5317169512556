import { ref } from "vue"

const getSinglePoliceForce = ( forceid ) => {
    
    const forceurl = 'https://data.police.uk/api/forces/' + forceid
    const singleforce = ref([])
    const error = ref([null])

    const load = async() => {
        try {
            let singleforcedata = await fetch( forceurl )
            if( !singleforcedata.ok ) {
                throw Error('Force Not found')
            }
            singleforce.value = await singleforcedata.json()
        } catch (err) {
            error.value = err.message
        }
    }
    return { singleforce, error, load }
}
export default getSinglePoliceForce