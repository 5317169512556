<template>
    <option :value=force.id>{{ force.name }}</option>
</template>

<script>
export default {
    props: ['force']
}
</script>

<style>

</style>