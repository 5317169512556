import { ref } from 'vue'

const getPostsTable = () => {
    const posts = ref([    ])
    const error = ref(null)
    const postscount = ref()

    const load = async() => {
      try {
        let data = await fetch('https://jsonplaceholder.typicode.com/posts')
        if( !data.ok ) {
          throw Error('No data available')
        }
        posts.value = await data.json();
        // console.log(posts.value.slice(0,5));
        // posts.total = posts.value.length;
        postscount.value = posts.value.length;
        // console.log(allposts);
      } catch (err) {
        error.value = err.message
        console.log(err.value)
      }
    }

    return { posts, error, load, postscount }
}

export default getPostsTable
