import { ref } from "vue"

const getSeniorOfficers = ( forceid ) => {
    
    const forceurl = 'https://data.police.uk/api/forces/' + forceid + '/people'
    const seniorofficers = ref([])
    const error = ref([null])

    const load = async() => {
        try {
            let seniorofficersdata = await fetch( forceurl )
            if( !seniorofficersdata.ok ) {
                throw Error('Force Not found')
            }
            seniorofficers.value = await seniorofficersdata.json()
        } catch (err) {
            error.value = err.message
        }
    }
    return { seniorofficers, error, load }
}
export default getSeniorOfficers