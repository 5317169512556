import { ref } from 'vue'

const getPosts = () => {
    const posts = ref([    ])
    const error = ref(null)

    const load = async() => {
      try {
        let data = await fetch('https://jsonplaceholder.typicode.com/posts')
        if( !data.ok ) {
          throw Error('No data available')
        }
        posts.value = await data.json()
        // console.log(posts.value.slice(0,5));
      } catch (err) {
        error.value = err.message
        console.log(err.value)
      }
    }

    return { posts, error, load }
}

export default getPosts