<template>
  <div v-if="seniorofficers.length>2">
    <div class="col-md-12 h-100 p-5 bg-body-tertiary border rounded-3">
      <h1>Senior Officers</h1>
    <div v-for="seniorofficer in seniorofficers" :key="seniorofficer">
        <h3>{{ seniorofficer.name }}</h3>
        <h5>{{ seniorofficer.rank }}</h5>
        <!-- <p>{{cleantext(seniorofficer.bio)}}</p> -->
        <p v-html="seniorofficer.bio"></p>
        <!-- <p>{{ seniorofficer.contact_details }}</p> -->
        <p v-if="seniorofficer.contact_details.length" v-html="seniorofficer.contact_details"></p>
    </div>
    </div>
  </div>
</template>

<script>
import getSeniorOfficers from '@/composables/getSeniorOfficers';

export default {
    name: 'SeniorOfficers',
    props: ['forceid'],
    setup(props){
        const { seniorofficers, error, load } = getSeniorOfficers( props.forceid )
        load()

        const cleantext = ( bio ) => {
          // console.log(bio)
          let str = bio
          str = str.replaceAll("<p>", "")
          str = str.replaceAll("</p>", "")
          str = str.replaceAll('{}', "")
          return str
        }

        return { seniorofficers, error, load, cleantext }
    }
}
</script>

<style>

</style>