import { ref } from "vue"

const getStopAndSearchAvailiability = () => {
    const availabilityurl = 'https://data.police.uk/api/crimes-street-dates'
    const stopandsearchavailability = ref()
    const error = ref()

    const load = async() => {
        try {
            let stopsearchavailabilitydata = await fetch( availabilityurl )
            if( !stopsearchavailabilitydata.ok ) {
                throw Error('Force Not found')
            }
            stopandsearchavailability.value = await stopsearchavailabilitydata.json()
        } catch (err) {
            error.value = err.message     
            console.log(err)     
        }
    }
    return { stopandsearchavailability, error, load }
}
export default getStopAndSearchAvailiability

export async function useGetSSavailability() {
    const availabilityurl = 'https://data.police.uk/api/crimes-street-dates'
    const stopandsearchavailability = ref()
    const error = ref()

    try {
        let stopsearchavailabilitydata = await fetch( availabilityurl )
        if( !stopsearchavailabilitydata.ok ) {
            throw Error('Force Not found')
        }
        stopandsearchavailability.value = await stopsearchavailabilitydata.json()
    } catch (err) {
        error.value = err.message     
        console.log(err)     
    }
    return { stopandsearchavailability, error }
}