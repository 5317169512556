<template>
    <div v-if="forcelist">
    <label for="selectForce" class="form-label fs-4">Select a Force</label>
        <select id="selectForce" 
            class="form-select" 
            @change="handleChange"
        >
        <option selected>Choose...</option>
            <ForceOption v-for="force in forcelist" :key="force.id" :force="force" />
        </select>

    </div>
    <div v-else>Loading...</div>
</template>

<script>

import ForceOption from '@/components/ForceOption';
import { ref } from 'vue';

export default {
    props:['forcelist'],
    components: { ForceOption },
    emits: ['optionEmitted'],
    setup(props,{emit}) {
        const handleChange = (id) => {
            // console.log(id.target.value)
            emit( 'optionEmitted', id.target.value )
        }
        return { handleChange }
    }
}
</script>

<style>

</style>