import { createApp, reactive } from 'vue'
// import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.min.css"

const GStore = reactive({ flashMessage: '' })


// Vuetify
//Styles
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'vuetify/styles'
// Composables
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { md3 } from 'vuetify/blueprints'

// Components
import App from './App.vue'

const vuetify = createVuetify({
  components,
  directives,
  blueprint: md3
})

createApp(App)
    .use(router)
    .use(vuetify)
    .provide('GStore', GStore)
    .mount('#app')


import "bootstrap/dist/js/bootstrap.js"