<template>
  <div>

    <main>
    <div v-if="suggestion">

      <div v-if="suggestion.error">
        <div class="card border-danger">
          <div class="card-body text-danger">{{suggestion.error}}</div>
        </div>
      </div>

      <div v-else>
        <div class="card mb-5">

        <div class="card-body">
          <h2 class="card-title">{{suggestion.activity}}</h2>
          
        </div>
         <ul class="list-group list-group-flush">
          <li class="list-group-item font-monospace"><h4 class="card-subtitle mb-2 text-body-secondary pt-2">{{ suggestion.type }}</h4></li>
          <li class="list-group-item font-monospace">Participants <span class="fw-semibold">{{ suggestion.participants }}</span></li>
          <li class="list-group-item font-monospace">Price <span class="fw-semibold">{{ suggestion.price }}</span></li>
          <li class="list-group-item font-monospace">Accessibility <span class="fw-semibold">{{ suggestion.accessibility }}</span></li>
        </ul>
        <div class="card-body">
          <a class="card-link text-secondary">{{suggestion.link}}</a>
        </div>
       
      </div>

      </div>

    </div>
    </main>


  </div>
</template>

<script>
import getBored from '@/composables/getBored'

export default {
  props: ['type', 'party'],
  
  setup(props) {
    // console.log(props)
    // console.log('type>>>'+props.type)
    // console.log('party>>>'+props.party)
    let typo = ( props.type && props.type !=='' && props.type !=='undefined' ) ? props.type : null
    let parties = props.party && props.party !=='' && props.party !== 'undefined' ? props.party : null

    if( props.type !=='' && props.type !=='undefined' ) {

      const {suggestion, error, load} = getBored(typo, parties)
      load()
      return {suggestion, error, load}

    }

  }

}
</script>

<style>

</style>