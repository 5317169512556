import { ref } from "vue";

const getBored = (btype, party) => {
    const suggestion = ref([null])
    const error = ref([null])
    const url = 'https://www.boredapi.com/api/activity/'
    let typeurl = ''

    if( btype !=='' && btype !=='undefined' && btype !==null) {
        typeurl = '&type=' + btype
    }

    let partyurl = ''
    if( party && party !==null) {
        partyurl = '&participants=' + party
    }

    const amp = () => {
        if( party || btype ) {
            // console.log(party, btype)
        return '?'
        } else {
            return ''
        }
    } 

    const load = async() => {
        try {
            // console.log(typeurl, partyurl)
            let suggestiondata = await fetch( url + amp() + typeurl + partyurl )
            if( !suggestiondata.ok ) {
                throw Error('Computer says no!')
            }
            suggestion.value = await suggestiondata.json()
        } catch (err) {
            error.value = err.message
            // console.log( err.value )
        }
    }
    return { suggestion, error, load }
}
export default getBored