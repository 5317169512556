<template>
    <h2 class="display-4">Comments</h2>
    <article v-for="comment in comments" :key=comment.id>
        <h2 class="display-6 link-body-emphasis mb-1">{{ comment.name }}</h2>
        <h4>{{ comment.email }}</h4>
        <p class="lead my-3">{{ comment.body }}</p>
    </article>
</template>

<script>
import getPostComments from '@/composables/getPostComments';
export default {
    name: 'Comments',
    props:['postid'],
    setup( props ) 
    {
        const { comments, error, loadcomments } = getPostComments( props.postid )
        loadcomments()
        return { comments, error }
    }
}
</script>

<style>

</style>