import { ref } from "vue"

const getPostComments = (postid) => {
    const comments = ref([])
    const error = ref()
    const postcommenturl = 'https://jsonplaceholder.typicode.com/posts/' + postid + '/comments'
    
    const loadcomments = async () => {
        try {
            let postcommentsdata = await fetch( postcommenturl )
            if ( !postcommentsdata.ok ) {
                throw Error( 'No Comments Available' )
            }
            comments.value = await postcommentsdata.json( )
        } catch (err) {
            error.value = err.message
        }
    }
    return { comments, error, loadcomments }
}
export default getPostComments

